<template>
  <div id="content" style="flex: 1 1 auto; overflow-y: auto; min-height: 0px;">
    <div id="loading" v-if="this.$store.state.statistics.loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>{{ $t('message.statistics_connection_chart') }} </h1>
      </header>
      <div class="container-fluid">
        <div class="row">
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="selectedType" :items="types" item-text="label" item-value="key" label="Type of Graphiques"
                          :rules="typeRules" required
                          persistent-hint return-object single-line>
                </v-select>
              </v-col>
              <!--  From -->
              <v-col cols="12" sm="12" md="3">
                <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y max-width="290px"
                        min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mb-2"
                                  persistent-hint
                                  label="De"
                                  prepend-icon="mdi-calendar"
                                  autocomplete="off"
                                  :value="from"
                                  readonly
                                  @focus="datepicker = true"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="dateRules"
                                  >
                    </v-text-field>
                  </template>
                  <v-date-picker v-if="datepicker" v-model="from" no-title for></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="4"></v-col>
              <!--  button      -->
              <v-col cols="12" sm="12" md="2" class="d-flex align-items-center align-right">
                <v-btn elevation="2" class="primary v-btn text-white" @click="searchForm">
                  Valider
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <div class="row">
          <div class="col-md-12"></div>
          <div class="col-md-1 col-sm-1"></div>
          <div class="col-md-10 col-sm-10">
            <div class="text-center" v-if="!existsData()">
              <h2>Il n'y a aucune donnée avant ce date</h2>
            </div>
            <canvas ref="connectionsChart"></canvas>
          </div>
          <div class="col-md-1 col-sm-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultLayout from '../../../layouts/DefaultLayout.vue'
import Chart from 'chart.js/auto'
import moment from 'moment'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(ChartDataLabels)

export default {
  name: 'ConnectionChart',
  components: {},
  data: () => ({
    chart: null,
    datepicker: false,
    from: null,
    to: null,
    selectedType: null,
    ranges: null,
    menuFrom: false,
    menuTo: false,
    maxValue: 1,
    types: [
      { key: 'less_30_days', label: '30 derniers jours' },
      { key: 'less_6_months', label: '6 derniers mois' }
    ],
    valid: false,
    errors: [],
    dateRules: [
      v => !!v || 'Date is required'
    ],
    typeRules: [
      value => {
        if (value) return true

        return 'Type is required.'
      }
    ]
  }),
  computed: {
    loading: {
      get: function () {
        return this.$store.state.statistics.loading
      },
      set: function (value) {
        this.$store.commit('storageStore/setLoading', value)
      }
    },
  },
  mounted () {
    this.from = moment().format('YYYY-MM-DD')
    this.selectedType = { key: 'less_30_days', label: '30 derniers jours' }
  },
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.$store.dispatch('statistics/getData',
      {
        from: this.from,
        chart: 'connections'
      })
  },
  methods: {
    searchForm () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('statistics/getData',
          {
            from: this.from ? this.from : null,
            chart: 'connections',
          })
      }
    },
    formatData () {
      const info = {}
      const backgroundColor = [
        '#0e5fe5',
        '#56a8b5',
        '#ea6b00'
      ]
      const hoverBackgroundColor = [
        '#072256',
        '#2e5a64',
        '#833e02'
      ]
      for (const [key, charts] of Object.entries(this.$store.state.statistics.data.data)) {
        info[key] = {}
        const labels = []
        const datasets = []
        let i = 0
        for (const key in charts) {
          const dataset = {
            label: '',
            data: [],
            backgroundColor: backgroundColor[i],
            hoverBackgroundColor: hoverBackgroundColor[i]
          }

          for (const range in charts[key]) {
            if (labels.indexOf(this.$t('message.' + charts[key][range].legend)) === -1) {
              labels.push(this.$t('message.' + charts[key][range].legend))
            }
            dataset.label = charts[key][range].label
            dataset.data.push(charts[key][range].value)
            this.calculateMaxValue(charts[key][range].value)
          }

          datasets.push(dataset)
          i++
        }

        const text = 'Nombre des Connexions des ' +
          this.selectedType.label.toLowerCase() +
          ' à partir de ' + this.$options.filters.dateFormat(this.$store.state.statistics.data.date)

        info[key] = {
          text: text,
          labels,
          datasets
        }
      }

      return info
    },
    calculateMaxValue (newValue) {
      if (this.maxValue < newValue) {
        this.maxValue = ((newValue * 100) / 90) + this.maxValue
      }
    },
    existsData () {
      if (this.$store.state.statistics.data === null || this.$store.state.statistics.data === undefined) return false
      if (this.$store.state.statistics.data.length === 0) return false

      return true
    },
    loadChart () {
      if (this.chart) {
        this.chart.destroy()
      }

      if (!this.existsData()) return false

      const info = this.formatData()
      Chart.defaults.elements.arc.hoverBorderColor = 'white'
      Chart.defaults.datasets.doughnut.cutout = '50%'
      this.chart = new Chart(this.$refs.connectionsChart, {
        type: 'bar',
        data: info[this.selectedType.key],
        options: {
          scales: {
            x: {
              max: this.maxValue
            }
          },
          indexAxis: 'y',
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          elements: {
            bar: {
              borderWidth: 0
            }
          },
          responsive: true,
          plugins: {
            title: {
              text: info[this.selectedType.key].text,
              display: true,
              padding: {
                top: 10,
                bottom: 30
              }
            },
            tooltip: {
              enabled: true
            },
            legend: {
              position: 'right'
            },
            datalabels: {
              // Position of the labels
              // (start, end, center, etc.)
              anchor: 'end',
              // Alignment of the labels
              // (start, end, center, etc.)
              align: 'end',
              // Color of the labels
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: function (value, context) {
                // Display the actual data value
                return value
              }
            }
          }
        },
      })
    },
  },
  watch: {
    loading () {
      if (!this.$store.state.statistics.loading) {
        this.loadChart()
      }
    },
    selectedType () {
      if (!this.$store.state.statistics.loading) {
        this.loadChart()
      }
    }
  }

}
</script>

<style scoped>

</style>
